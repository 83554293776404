




import Vue from "vue";
import LogoutComponent from "@/components/LogoutComponent.vue";

export default Vue.extend({
  name: "Logout",

  components: {
    LogoutComponent,
  },
});
